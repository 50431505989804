import { 
    request
} from './api';

export const getHotSearchList = (resolve) => {
    return request(`${process.env.VUE_APP_API_URL}/hot_search/search`, "get", {}, resolve);
}

export const saveHotSearch = (data, resolve) => {
    return request(`${process.env.VUE_APP_API_URL}/hot_search`, "post", data, resolve);
}

export const editHotSearch = (data, resolve) => {
    return request(`${process.env.VUE_APP_API_URL}/hot_search`, "put", data, resolve);
}

export const deleteAllHotSearch = (resolve) => {
    return request(`${process.env.VUE_APP_API_URL}/hot_search/delete`, "delete", {}, resolve);
}