import { 
    request
} from './api';

export const getLoginLogList = (data, resolve) => {
    return request(`${process.env.VUE_APP_API_URL}/login_log`, "get", data, resolve);
}

export const deleteAllLoginLogList = (resolve) => {
    return request(`${process.env.VUE_APP_API_URL}/login_log`, "delete", {}, resolve);
}

export const deleteLoginLogList = (data, resolve) => {
    return request(`${process.env.VUE_APP_API_URL}/login_log`, "delete", data, resolve);
}