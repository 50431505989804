import { 
    request
} from './api';

export const getFeedbackList = (params, resolve) => {
    return request(`${process.env.VUE_APP_API_URL}/customer/feedback/search`, "get", params, resolve);
}

export const replyFeedback = (data, resolve) => {
    return request(`${process.env.VUE_APP_API_URL}/customer/feedback/update`, "put", data, resolve);
}

export const getFeedbackUndeliveredCount = (resolve) => {
    return request(`${process.env.VUE_APP_API_URL}/customer/feedback/unprocessed-count`, "get", {}, resolve);
}