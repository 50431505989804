import { request } from './api';

export const login = (username, password, resolve, reject) => {
    let data = { "username": username, password: password };
    return request(`${process.env.VUE_APP_API_BASE_URL}/auth/login`, "post", data, resolve, reject);
}

export const getUserList = (resolve) => {
    return request(`${process.env.VUE_APP_API_URL}/users`, "get", {}, resolve);
}

export const getUser = (userName, resolve) => {
    return request(`${process.env.VUE_APP_API_URL}/user/get/${userName}`, "get", {}, resolve);
}

export const saveUser = (data, resolve) => {
    return request(`${process.env.VUE_APP_API_URL}/user`, "post", data, resolve);
}

export const editUser = (data, resolve) => {
    return request(`${process.env.VUE_APP_API_URL}/user`, "put", data, resolve);
}

export const deleteUser = (id, resolve) => {
    return request(`${process.env.VUE_APP_API_URL}/user/delete/${id}`, "delete", {}, resolve);
}