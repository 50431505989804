<template>
    <div>
        <b-navbar toggleable="lg" type="dark" variant="success">
            <b-container class="nav-container">
                <b-navbar-brand href="#" class="nav-brand">韵晴皮具小程序</b-navbar-brand>
                <b-navbar-toggle target="nav-collapse" class="nav-toggle"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav class="ml-auto">
                        <b-nav-item-dropdown right v-if="isLogin == true">
                            <template slot="button-content"><em style="font-style:normal">{{menuItem}}</em></template>
                            <b-dropdown-item href="#" @click="product">产品信息管理</b-dropdown-item>
                            <b-dropdown-item href="#" @click="homeSort">产品排序管理</b-dropdown-item>
                            <b-dropdown-item href="#" @click="customers">客户信息管理</b-dropdown-item>
                            <b-dropdown-item href="#" @click="orders">订单信息管理</b-dropdown-item>
                            <b-dropdown-item href="#" @click="orderstats">订单信息统计</b-dropdown-item>
                            <b-dropdown-item href="#" @click="feedbacks">反馈信息管理</b-dropdown-item>
                            <b-dropdown-item href="#" @click="loginLog">登录信息查询</b-dropdown-item>
                            <b-dropdown-item href="#" @click="notices">公告信息管理</b-dropdown-item>
                            <b-dropdown-item href="#" @click="hotSearch">热搜信息管理</b-dropdown-item>
                            <b-dropdown-item href="#" @click="users" v-if="isAdmin == true">用户信息管理</b-dropdown-item>
                            <b-dropdown-item href="#" @click="profile">当前登录用户</b-dropdown-item>
                            <b-dropdown-item href="#" @click="quit">退出登录</b-dropdown-item>
                        </b-nav-item-dropdown>
                    </b-navbar-nav>
                </b-collapse>
            </b-container>
        </b-navbar>
        <div style="width:100%;display:flex;flex-direction:row;justify-content:flex-start;align-items:center;margin-top:5px;margin-bottom:-10px;" v-if="isLogin == true">
            <div style="width:15%;">&nbsp;</div>
            <div style="width:85%;">
                <span style="font-size:14px;font-weight:bold;">
                工作提醒:
                </span>
                <b-badge variant="light" @click="gotoOrder"
                    :style="{ backgroundColor: currentSelection == 1 ? '#ccc' : '#fff', cursor: 'pointer', fontSize: '14px', fontWeight: 'normal' }">
                    当前待发货订单总数: {{undeliveredCount}} 条
                </b-badge>
                <b-badge variant="light" @click="gotoFeedback" 
                    :style="{ backgroundColor: currentSelection == 2 ? '#ccc' : '#fff', cursor: 'pointer', fontSize: '14px', fontWeight: 'normal' }">
                    当前未处理反馈总数: {{unProcessFeedbackCount}} 条
                </b-badge>
                <b-link href="#"  @click="refreshCounts()" style="font-size:14px;">
                    更新
                </b-link>
            </div>
        </div>
        <b-modal id="hot-search" title="热搜信息管理" size="lg"
            hide-footer
            v-model="modalShow">
            <div class="hot-search-container">
                <b-form-input v-model="addingName"
                    @keyup.enter.native="saveAddHotSearch"
                    @blur="saveAddHotSearch"
                    v-if="adding == true"
                    class="hot-search-item-input">
                </b-form-input>
                <b-link href="#"
                    @click="addHotSearch()"
                    style="font-size:14px;margin-left:20px;margin-right:10px;text-align:left;"
                    class="hot-search-item">
                    {{adding == true ? '保存': '添加'}}
                </b-link>
                <b-link href="#"
                    @click="clearHotSearch()"
                    style="font-size:14px;margin-left:10px;margin-right:10px;text-align:left;color:red"
                    class="hot-search-item">
                    清空
                </b-link>
            </div>
            <div v-if="hotSearches.length > 0" class="hot-search-container">
                <div v-for="(hot_search_item, index) in hotSearches" :key="index" class="hot-search-item" 
                    @click="editHotSearch(index)"
                    @mouseover="deletingIndex = index"
                    @mouseout="deletingIndex = -1">
                    <span v-if="editingIndex != index">
                        {{hot_search_item.name}}
                    </span>
                    <b-form-input v-model="hot_search_item.name" v-if="editingIndex == index"
                        @keyup.enter.native="saveHotSearch(index)"
                        @blur="saveHotSearch(index)"
                        class="hot-search-item-input">
                    </b-form-input>
                </div>
            </div>
            <div v-if="hotSearches.length == 0">
                热搜信息为空
            </div>
        </b-modal>
        <b-modal id="login-log" title="登录历史管理" size="lg"
            hide-footer
            v-model="modalLoginLogShow">
            <div v-if="loginLogs != null && loginLogs.length > 0">
                <div style="margin-bottom:10px;width:90%;text-align:left;">
                    <b-link href="#" @click="clearLoginHistory()"
                        style="font-size:13px;">
                        清空登录历史
                    </b-link>
                </div>
                <b-table :items="loginLogs" :fields="loginLogFields" 
                    small responsive hover selectable select-mode="single">
                    <template #cell(avatar_url)="data">
                        <b-img thumbnail fluid :src="data.item.avatar_url" 
                            center lazy width="30" height="30"
                            v-if="data.item.avatar_url!=''">
                        </b-img>
                    </template>
                    <template #cell(gender)="data">
                        {{getGender(data.item.gender)}}
                    </template>
                    <!-- <template #cell(last_login_time)="data">
                        {{dateFormat(data.item.last_login_time)}}
                    </template> -->
                    <template #cell(login_time)="data">
                        {{dateFormat(data.item.login_time)}}
                    </template>
                </b-table>
                <div style="margin-top:10px;width:90%;">
                    <b-pagination
                        v-model="currentLogPage"
                        :total-rows="totalLog"
                        :per-page="logPageSize"
                        align="center">
                    </b-pagination>
                    <div style="height:30px;line-height:30px;text-align:center;">
                        第 {{ currentLogPage }} 页
                        总计: {{ totalLog }} 行
                    </div>
                </div>
            </div>
            <div v-else style="margin:10px;">
                <h6>没有登录历史</h6>
            </div>
        </b-modal>
    </div>  
</template>

<script>
import { MessageBox } from 'mint-ui';

import {
    getHotSearchList, saveHotSearch, editHotSearch, deleteAllHotSearch
} from "api/hotsearch";

import { 
    getLoginLogList, deleteAllLoginLogList
} from "api/loginlog";

import {
    getOrderUndeliveredCount
} from "api/order";

import {
    getFeedbackUndeliveredCount
} from "api/feedback";

import {
    getUser
} from "api/user";

import bus from '@/api/bus';

export default {
    data() {
        return {
            menuItem: '',
            modalShow: false,
            modalLoginLogShow: false,
            hotSearches: [],
            editingIndex: -1,
            adding: false,
            addingName: '',
            deletingIndex: -1,
            loginLogs: [],
            showAllLoginLogs: false,
            loginLogFields: [],
            loginLogFieldsSimple: [
                {
                    key: 'avatar_url',
                    label: '头像'
                },
                {
                    key: 'name',
                    label: '姓名'
                },
                {
                    key: 'nick_name',
                    label: '昵称'
                },
                {
                    key: 'login_time',
                    label: '登录时间'
                }
            ],
            loginLogFieldsAll: [
                {
                    key: 'avatar_url',
                    label: '头像'
                },
                {
                    key: 'name',
                    label: '姓名'
                },
                {
                    key: 'nick_name',
                    label: '昵称'
                },
                {
                    key: 'customer_tel',
                    label: '手机'
                },
                {
                    key: 'gender',
                    label: '性别'
                },
                {
                    key: 'province',
                    label: '省份'
                },
                {
                    key: 'city',
                    label: '城市'
                },
                // {
                //     key: 'last_login_time',
                //     label: '最后登录时间'
                // },
                {
                    key: 'login_time',
                    label: '登录时间'
                }
            ],
            totalLog: 0,
            currentLogPage: 1,
            logPageSize: 20,
            isAdmin: false,
            isLogin: false,
            undeliveredCount: 0,
            unProcessFeedbackCount: 0,
            currentSelection: 0
        }
    },
    mounted() {
        this.setMenuItem();
        this.getHotSearches();
        this.isMobile = this.getIsMobile();
        if(this.isMobile) {
            this.loginLogFields = this.loginLogFieldsSimple;
        } else {
            this.loginLogFields = this.loginLogFieldsAll;
        }
        this.getIsAdmin();
        this.getIsLogin();
        
        this.getOrderUndeliveredCount();
        this.getFeedbackUndeliveredCount();

        bus.$on('refresh-order-count', () => {
            this.getOrderUndeliveredCount();
        });
        bus.$on('refresh-feedback-count', () => {
            this.getFeedbackUndeliveredCount();
        });
    },
    watch: {
        modalShow: function(newValue, oldValue) {
            if(newValue == false) {
                this.editingIndex = -1;
            }
        },
        currentLogPage: function(newValue, oldValue) {
            this.searchLoginLog();
        }
    },
    methods: {
        getIsAdmin() {
            let user = this.getLocalItem("user");
            getUser(user.userName, response => {
                if(response.status == 200) {
                    let { data } = response.data;
                    if(data) {
                        this.isAdmin = data.is_admin;
                    }
                }
            });
        },
        getIsLogin() {
            let user = this.getLocalItem("user");
            this.isLogin = user.userName != undefined && user.userName != null && user.userName != "";
        },
        refreshCounts() {
            this.getOrderUndeliveredCount();
            this.getFeedbackUndeliveredCount();
        },
        getOrderUndeliveredCount() {
            getOrderUndeliveredCount(response => {
                if(response.status == 200) {
                    let { data } = response.data;
                    this.undeliveredCount = parseInt(data);
                }
            });
        },
        getFeedbackUndeliveredCount() {
            getFeedbackUndeliveredCount(response => {
                if(response.status == 200) {
                    let { data } = response.data;
                    this.unProcessFeedbackCount = parseInt(data);
                }
            });
        },
        gotoOrder() {
            this.currentSelection = 1;
            if(this.$route.name == "Orders") {
                bus.$emit('refresh-order-data');
            } else {
                this.$router.push({name: 'Orders', params: {status: 1}});
                this.setMenuItem();
            }
        },
        gotoFeedback() {
            this.currentSelection = 2;
            if(this.$route.name == "Feedbacks") {
                bus.$emit('refresh-feedback-data');
            } else {
                this.$router.push({name: 'Feedbacks', params: {is_replied: 'not-replied'}});
                this.setMenuItem();
            }
        },
        getGender: function(value) {
            return this.getGenderString(value);
        },
        dateFormat: function(time) {
            return this.dateFormatString(time);
        },
        setMenuItem() {
            if(this.$router.currentRoute.meta && this.$router.currentRoute.meta.title) {
                let _this = this;
                setTimeout(() => {
                    _this.menuItem = _this.$router.currentRoute.meta.title;
                    document.title = "韵晴皮具信息管理系统 - " + _this.menuItem;
                }, 1000);
            }
        },
        product() {
            this.currentSelection = 0;
            this.$router.push("/product");
            this.setMenuItem();
        },
        homeSort() {
            this.currentSelection = 0;
            this.$router.push("/home-sort");
            this.setMenuItem();
        },
        customers() {
            this.currentSelection = 0;
            this.$router.push("/customer");
            this.setMenuItem();
        },
        orders() {
            this.currentSelection = 0;
            this.$router.push({name: 'Orders', params: {}});
            this.setMenuItem();
        },
        orderstats() {
            this.currentSelection = 0;
            this.$router.push("/order-statistic");
            this.setMenuItem();
        },
        feedbacks() {
            this.currentSelection = 0;
            this.$router.push({name: 'Feedbacks', params: {}});
            this.setMenuItem();
        },
        users() {
            this.currentSelection = 0;
            this.$router.push("/user");
            this.setMenuItem();
        },
        notices() {
            this.currentSelection = 0;
            this.$router.push("/notice");
            this.setMenuItem();
        },
        loginLog() {
            this.searchLoginLog();
            this.modalLoginLogShow = true;
        },
        hotSearch() {
            this.modalShow = true;
        },
        profile() {
            let userName = this.getLocalItem("user") && this.getLocalItem("user").userName;
            MessageBox('当前登录用户', userName);
        },
        quit() {
            this.clearLocalItems();
            this.$router.push("/login");
        },
        getHotSearches() {
            getHotSearchList(response => {
                if(response.status == 200) {
                    this.hotSearches = response.data.data;
                    this.total = response.data.total;
                }
            });
        },
        editHotSearch(index) {
            this.deletingIndex = -1;
            this.editingIndex = index;
            this.$forceUpdate();
        },
        saveHotSearch(index) {
            this.deletingIndex = -1;
            let hotSearch = this.hotSearches[index];
            if(hotSearch.name == '') {
                return;
            }
            let saveObject = Object.assign({}, hotSearch);
            editHotSearch(saveObject, response => {
                if(response.status == 200) {
                    this.editingIndex = -1;
                }
            });
        },
        clearHotSearch() {
            let _this = this;
            MessageBox.confirm('确定清空热搜?').then(action => {
                deleteAllHotSearch(resp => {
                    if(resp.status == 200) {
                        MessageBox('清空成功', '清空热搜成功');
                        _this.hotSearches = [];
                        _this.total = 0;
                        _this.$forceUpdate();
                    }
                });
            }).catch(()=>{});
        },
        addHotSearch() {
            this.deletingIndex = -1;
            if(this.adding == true) {
                this.saveAddHotSearch();
            } else {
                this.adding = true;
            }
        },
        saveAddHotSearch() {
            if(this.addingName == '') {
                this.adding = false;
                return;
            }
            let saveObject = {
                'name': this.addingName
            };
            this.addingName = '';
            saveHotSearch(saveObject, response => {
                if(response.status == 201) {
                    this.adding = false;
                    this.deletingIndex = -1;
                    this.getHotSearches();
                }
            });
        },
        searchLoginLog() {
            const query_login_logs_data = {
                "customer_id": 0,
                "page_size": this.logPageSize,
                "current_page": this.currentLogPage
            };
            getLoginLogList(query_login_logs_data, respLoginLogs => {
                if(respLoginLogs.status == 200) {
                    this.loginLogs = respLoginLogs.data.data;
                    this.totalLog = respLoginLogs.data.total;
                }
            });
        },
        clearLoginHistory() {
            let _this = this;
            MessageBox.confirm('确定清空登录历史?').then(action => {
                deleteAllLoginLogList(resp => {
                    if(resp.status == 200) {
                        MessageBox('清空成功', '清空登录历史成功');
                        _this.loginLogs = [];
                        _this.totalLog = 0;
                        _this.$forceUpdate();
                    }
                });
            }).catch(()=>{});
        }
    }
}    
</script>

<style scoped>
.nav-container {
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.nav-brand {
    width: 70%;
    padding-left: 10px;
}

.nav-toggle {
    width: 20%;
}

.hot-search-container {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width: 90%;
    flex-wrap:wrap;
}

.hot-search-item {
    width: 8%;
    min-width: 100px;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    position: relative;
}

.hot-search-item-input {
    width: 100px;
    text-align: center;
}
</style>